<script setup>
  import MarkdownIt from 'markdown-it'

  const props = defineProps({
    content: { required: true, type: String },
    options: {
      default: { breaks: true, html: true, xhtmlOut: true },
      type: Object
    },
    preset: { default: 'default', type: String },
    prose: { default: true, type: Boolean }
  })

  const md = new MarkdownIt(props.preset, props.options)
  md.disable(['code', ])
</script>

<template>
  <div
    v-html="md.render(props.content || '')"
    :class="{
      'prose max-w-none leading-normal prose-p:mb-0 prose-p:mt-4 first:prose-p:mt-0 prose-ul:mb-0 prose-ul:ml-4 prose-ul:mt-4 prose-ul:space-y-1 prose-ul:pl-0 marker:prose-ul:text-viking-400 first:prose-ul:mt-0 prose-li:m-0 prose-li:pl-0':
        prose
    }"
  />
</template>
